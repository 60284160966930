export const MAIN_PATH = () => '/';
export const AUTH_PATH = () => '/auth';
export const SOCIAL_OAUTH_PATH = () => '/social_oauth';
export const SEARCH_PATH = (searchWord: string) => `/search/${searchWord}`; 
export const USER_PATH = (userEmail: string) => `/user/${userEmail}`;
export const STORE_PATH = () => `/store`;
export const ITEM_PATH = () => `/item`;
export const ITEM_DETAIL_PATH = (itemId: string | number) => `detail/${itemId}`;
export const ITEM_UPDATE_PATH = (itemId: string | number) => `update/${itemId}`;
export const BOARD_PATH = () => '/board';
export const BOARD_DETAIL_PATH = (boardNumber: string | number) => `detail/${boardNumber}`;
export const BOARD_WRITE_PATH = () => 'write';
export const BOARD_UPDATE_PATH = (boardNumber: string | number) => `update/${boardNumber}`;